<template>
  <p-page v-if="workSpaceStore.workspace" send-analytics>
    <p-section headline="Active campaign statistics" :headline-size="headlineSize">
      <p-row align-items="center" v-device-desktop>
        <p-column>
          <p-tabs>
            <p-tabs-item
              v-for="tab in dateRanges"
              :key="tab.id"
              :selected.prop="selectedDateRange === tab.id"
              :text="tab.label"
              size="medium"
              @select="selectedDateRange = String(tab.id)"
            />
          </p-tabs>
        </p-column>
        <p-column>
          <p-row justify-content="end">
            <p-form-date-picker size="small" v-model="selectedFromDate" label="From" />
            <p-form-date-picker size="small" v-model="selectedToDate" label="To" />
          </p-row>
        </p-column>
      </p-row>

      <p-row align-items="center" v-device-mobile>
        <p-column size="extra-large">
          <p-tabs>
            <p-tabs-item
              v-for="tab in dateRanges"
              :key="tab.id"
              :selected.prop="selectedDateRange === tab.id"
              :text="tab.label"
              size="medium"
              @select="selectedDateRange = String(tab.id)"
            />
          </p-tabs>
        </p-column>
        <p-column size="extra-large">
          <p-row justify-content="space-between">
            <p-form-date-picker size="medium" v-model="selectedFromDate" label="From" />
            <p-form-date-picker size="medium" v-model="selectedToDate" label="To" />
          </p-row>
        </p-column>
      </p-row>

      <p-row>
        <p-column justify-content="center">
          <p-chart-area :data-sets="chartData" :loading="waitingForChartData" />
        </p-column>
      </p-row>

      <p-row>
        <p-column align-items="center">
          <p-tabs>
            <p-tabs-item
              v-for="tab in chartDisplayTabs"
              :key="tab.id"
              :selected.prop="selectedChartDisplay === tab.id"
              :text="tab.label"
              size="medium"
              @select="selectedChartDisplay = String(tab.id)"
            />
          </p-tabs>
        </p-column>
      </p-row>

      <p-form-select
        slot="toolbar"
        v-device-desktop
        v-model="selectedCampaign"
        :options="activeCampaignOptions"
        :loading-options="waitingForActiveCampaignOptions"
        searchable
        size="medium"
        label="Show data from"
        @search="onCampaignSearchDebounced($event.detail[0])"
      />

      <p-form-select
        slot="toolbar"
        v-device-mobile
        v-model="selectedCampaign"
        :options="activeCampaignOptions"
        :loading-options="waitingForActiveCampaignOptions"
        searchable
        size="large"
        label="Show data from"
        @search="onCampaignSearchDebounced($event.detail[0])"
      />
    </p-section>

    <p-row align-items="stretch" v-if="workSpaceStore.workspace?.plan_alias === 'free'">
      <p-column>
        <p-custom-slide-show :slides="slideShowSlides" />
      </p-column>

      <p-column>
        <p-section min-height-full>
          <p-headline size="3">Getting started</p-headline>
          <p-paragraph>A collection of articles that helps you getting started with Playable campaigns!</p-paragraph>
          <p-link href="https://help.playable.com/en/collections/3868004-getting-started" target="_blank">
            <p-button>Get access to the help center</p-button>
          </p-link>

          <p-container gap-size="none">
            <p-paragraph
              >Did you miss the intro video?<p-button
                size="medium"
                color-type="tertiary"
                @click="showFreemiumIntroVideo = true"
                >watch it again</p-button
              ></p-paragraph
            >

            <p-paragraph
              >Want to speak to Sales?
              <p-link href="https://playable.com/discovery/" target="_blank">
                <p-button size="medium" color-type="tertiary">Reach out</p-button>
              </p-link>
            </p-paragraph>
          </p-container>
        </p-section>
      </p-column>
    </p-row>

    <p-modal-plain
      :show.prop="true"
      v-if="showFreemiumIntroVideo && workSpaceStore.workspace?.plan_alias === 'free'"
      @close-request="showFreemiumIntroVideo = !showFreemiumIntroVideo"
    >
      <p-column>
        <p-container align-items="center">
          <p-headline>Hi, {{ userStore.user?.name }} 👋</p-headline>
          <p-headline size="3">Welcome to Playable The gamification platform for marketers</p-headline>
          <p-paragraph>
            Let's start with a quick introduction to our gamification software and we will have you up and running in no
            time !
          </p-paragraph>
          <div class="iframe-container">
            <iframe
              src="https://player.vimeo.com/video/875118884?h=80baefefc2"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
        </p-container>
      </p-column>
    </p-modal-plain>

    <p-row>
      <p-column v-if="workSpaceStore.workspace?.enable_statistics">
        <p-custom-stat-box
          headline="Total sessions"
          icon="user"
          :loading="waitingForChartData"
          :value="totalSessions"
        />
      </p-column>

      <p-column>
        <p-custom-stat-box
          headline="Registrations"
          icon="users"
          :loading="waitingForChartData"
          :value="totalRegistrations"
        />
      </p-column>

      <p-column>
        <p-custom-stat-box
          headline="Active campaigns"
          icon="sun"
          :loading="waitingForActiveCampaigns || waitingForActivePopupCampaigns"
          :value="(activeCampaigns?.meta.total ?? 0) - (activePopupCampaigns?.meta.total ?? 0)"
        />
      </p-column>

      <p-column>
        <p-custom-stat-box
          headline="Active pop-ups"
          icon="credit-card"
          :loading="waitingForActivePopupCampaigns"
          :value="activePopupCampaigns?.meta.total"
        />
      </p-column>
    </p-row>

    <p-row align-items="stretch" v-device-desktop>
      <p-column>
        <p-custom-stat-box-inverted headline="Real time statistics">
          <template #left>
            <div class="devices">
              <div class="devices__item">
                <div class="devices__item-color devices__item-color--mobile"></div>
                <div class="devices__item-text">
                  <p-skeleton-loader v-if="waitingForRealtimeData" type="text" />
                  <template v-else>
                    <span>{{ realtimeText.mobile }}</span>
                    <span>Mobile</span>
                    <span>{{ realtimePercentage.mobile }}%</span>
                  </template>
                </div>
              </div>

              <div class="devices__item">
                <div class="devices__item-color devices__item-color--desktop"></div>
                <div class="devices__item-text">
                  <p-skeleton-loader v-if="waitingForRealtimeData" type="text" />
                  <template v-else>
                    <span>{{ realtimeText.desktop }}</span>
                    <span>Desktop</span>
                    <span>{{ realtimePercentage.desktop }}%</span>
                  </template>
                </div>
              </div>

              <div class="devices__item">
                <div class="devices__item-color devices__item-color--tablet"></div>
                <div class="devices__item-text">
                  <p-skeleton-loader v-if="waitingForRealtimeData" type="text" />
                  <template v-else>
                    <span>{{ realtimeText.tablet }}</span>
                    <span>Tablet</span>
                    <span>{{ realtimePercentage.tablet }}%</span>
                  </template>
                </div>
              </div>
            </div>

            <div class="info">
              <p-help-text v-if="workSpaceStore.workspace?.enable_statistics">
                The numbers above show the current players interacting with your campaign(s).
              </p-help-text>
              <p-help-text v-else>
                The real-time statistic isn't shown due to the platform statistic being disabled.
              </p-help-text>
            </div>
          </template>

          <template #right>
            <div class="stat-box-chart">
              <p-chart-doughnut
                :loading="waitingForRealtimeData"
                :data-sets="realtimeChartData"
                :labels="realtimeLabels"
              />
            </div>
          </template>
        </p-custom-stat-box-inverted>
      </p-column>

      <p-column>
        <p-custom-stat-box-inverted headline="Platform use">
          <template #left>
            <div class="info">
              <template v-if="waitingForLatestCampaigns || waitingForCampaignTypes">
                <p-skeleton-loader type="text"><div class="info__sub">&nbsp;</div></p-skeleton-loader>

                <p-skeleton-loader type="text"><div class="info__main">&nbsp;</div></p-skeleton-loader>

                <p-skeleton-loader type="text"><p-help-text>&nbsp;</p-help-text></p-skeleton-loader>
              </template>
              <template v-else-if="latestCampaignCreated">
                <div class="info__sub">Latest campaign created</div>

                <p-link :href="latestCampaignCreated.url">
                  <div class="info__main">{{ latestCampaignCreated.name }}</div>
                </p-link>

                <p-help-text>
                  {{ latestCampaignCreated.type }}. Last worked on at {{ latestCampaignCreated.changed_on }}
                </p-help-text>
              </template>
              <template v-else>
                <div class="info__sub">Latest campaign created</div>
                <div class="info__main">None</div>
                <p-help-text>last worked on --/--/----</p-help-text>
              </template>
            </div>
          </template>

          <template #right>
            <p-image :src="platformUseAsset" />
          </template>
        </p-custom-stat-box-inverted>
      </p-column>
    </p-row>

    <p-row align-items="stretch" v-device-mobile>
      <p-column size="extra-large">
        <p-custom-stat-box-inverted headline="Real time statistics">
          <template #left>
            <div class="devices">
              <div class="devices__item">
                <div class="devices__item-color devices__item-color--mobile"></div>
                <div class="devices__item-text">
                  <p-skeleton-loader v-if="waitingForRealtimeData" type="text" />
                  <template v-else>
                    <span>{{ realtimeText.mobile }}</span>
                    <span>Mobile</span>
                    <span>{{ realtimePercentage.mobile }}%</span>
                  </template>
                </div>
              </div>

              <div class="devices__item">
                <div class="devices__item-color devices__item-color--desktop"></div>
                <div class="devices__item-text">
                  <p-skeleton-loader v-if="waitingForRealtimeData" type="text" />
                  <template v-else>
                    <span>{{ realtimeText.desktop }}</span>
                    <span>Desktop</span>
                    <span>{{ realtimePercentage.desktop }}%</span>
                  </template>
                </div>
              </div>

              <div class="devices__item">
                <div class="devices__item-color devices__item-color--tablet"></div>
                <div class="devices__item-text">
                  <p-skeleton-loader v-if="waitingForRealtimeData" type="text" />
                  <template v-else>
                    <span>{{ realtimeText.tablet }}</span>
                    <span>Tablet</span>
                    <span>{{ realtimePercentage.tablet }}%</span>
                  </template>
                </div>
              </div>
            </div>

            <div class="info">
              <p-help-text v-if="workSpaceStore.workspace?.enable_statistics">
                The numbers above show the current players interacting with your campaign(s).
              </p-help-text>
              <p-help-text v-else>
                The real-time statistic isn't shown due to the platform statistic being disabled.
              </p-help-text>
            </div>
          </template>
        </p-custom-stat-box-inverted>
      </p-column>

      <p-column size="extra-large">
        <p-custom-stat-box-inverted headline="Platform use">
          <template #left>
            <div class="info">
              <template v-if="waitingForLatestCampaigns || waitingForCampaignTypes">
                <p-skeleton-loader type="text"><div class="info__sub">&nbsp;</div></p-skeleton-loader>

                <p-skeleton-loader type="text"><div class="info__main">&nbsp;</div></p-skeleton-loader>

                <p-skeleton-loader type="text"><p-help-text>&nbsp;</p-help-text></p-skeleton-loader>
              </template>
              <template v-else-if="latestCampaignCreated">
                <div class="info__sub">Latest campaign created</div>

                <p-link :href="latestCampaignCreated.url">
                  <div class="info__main">{{ latestCampaignCreated.name }}</div>
                </p-link>

                <p-help-text>
                  {{ latestCampaignCreated.type }}. Last worked on at {{ latestCampaignCreated.changed_on }}
                </p-help-text>
              </template>
              <template v-else>
                <div class="info__sub">Latest campaign created</div>
                <div class="info__main">None</div>
                <p-help-text>last worked on --/--/----</p-help-text>
              </template>
            </div>
          </template>
        </p-custom-stat-box-inverted>
      </p-column>
    </p-row>

    <p-section>
      <p-modal-confirm
        :show.prop="true"
        v-if="campaignDeleteConfirm"
        :confirm-text="campaignDeleteConfirm.name"
        description="Deletion of this campaign cannot be undone!"
        :title="confirmModalTitle"
        :callback.prop="deleteCampaign"
        @close-request="campaignDeleteConfirm = null"
      />

      <p-table-client
        :pagination="false"
        :searchable="false"
        :header="[
          { id: 'name', label: 'Name', width: '240px', verticalAlign: 'top' },
          { id: 'id', label: 'ID', width: '140px', verticalAlign: 'top' },
          { id: 'creator', label: 'Creator', width: '140px', verticalAlign: 'top' },
          { id: 'activity', label: 'Activity', width: '140px', verticalAlign: 'top' },
          { id: 'creation', label: 'Creation', width: '140px', nowrap: true, verticalAlign: 'top' },
          { id: 'actions', verticalAlign: 'top' }
        ]"
        :body="latestCampaignsBody"
        :skeleton-loader="waitingForLatestCampaigns || waitingForCampaignTypes"
        :skeleton-loader-rows="5"
        :skeleton-loader-text-lines="2"
      >
        <template #toolbar>
          <p-column size="medium">
            <p-headline size="3">Latest campaigns</p-headline>
          </p-column>

          <p-column size="extra-large">
            <p-row align-items="flex-end" justify-content="end">
              <p-form-select
                v-model="selectedUser"
                size="small"
                label="User"
                :options="userOptions"
                :disabled="waitingForLatestCampaigns || waitingForCampaignTypes"
              />

              <p-form-select
                v-model="selectedStatus"
                size="small"
                label="Status"
                :options="statusOptions"
                :disabled="waitingForLatestCampaigns || waitingForCampaignTypes"
              />

              <p-form-select
                v-model="selectedGameType"
                size="small"
                label="Game type"
                :options="campaignTypeOptions"
                :disabled="waitingForLatestCampaigns || waitingForCampaignTypes"
              />

              <p-link href="/campaigns">
                <p-button size="medium">Show all campaigns</p-button>
              </p-link>
            </p-row>
          </p-column>
        </template>

        <!-- eslint-disable-next-line -->
        <template #bodyCell="{ row, column, header }">
          <!-- Name -->
          <template v-if="header.id === 'name'">
            <p-tooltip :text="column" v-if="column && column.length > 10">
              <div class="campaign-name">{{ column }}</div>
            </p-tooltip>
            <div class="campaign-name" v-else>{{ column }}</div>

            <p-row gap-size="small">
              <p-badge v-if="row.device_type" type="info" :text="row.device_type" />
              <p-badge v-if="row.template?.content" type="info" text="Content" />
              <p-badge v-if="row.template?.layout" type="info" text="Layout" />
            </p-row>
          </template>

          <!-- ID -->
          <template v-else-if="header.id === 'id'">
            <p-paragraph>{{ column }}</p-paragraph>

            <span class="decorative-text">
              <p-paragraph typography="component-text-small" v-if="row.typeName">{{ row.typeName }}</p-paragraph>
            </span>
          </template>

          <!-- Activity -->
          <template v-else-if="header.id === 'activity' && row.permissions.statistic">
            <p-paragraph
              ><p-link :href="`/campaign/${row.type}/${row.id}/activity/statistics`">{{ row.sessions }}</p-link>
              sessions</p-paragraph
            >

            <span class="decorative-text">
              <p-paragraph typography="component-text-small">{{ row.registrations }} registrations</p-paragraph>
            </span>
          </template>

          <!-- Creator -->
          <template v-else-if="header.id === 'creator'">
            <p-tooltip :text="column" v-if="column && column.length > 10">
              <div class="creator-name">{{ column }}</div>
            </p-tooltip>
            <div class="creator-name" v-else>{{ column }}</div>
          </template>

          <!-- Created on -->
          <template v-else-if="header.id === 'creation'">
            <div class="campaign-creation">
              <p-paragraph>{{ row.created_on }}</p-paragraph>

              <p-badge v-if="row.state === 'paused'" type="negative" text="Paused" />
              <p-badge v-else-if="row.state === 'live' && row.active_always" type="positive" text="Always on" />
              <p-badge
                v-else-if="row.state === 'live' && !row.active_always"
                type="positive"
                :text="`Live, ${row.days_left_live} days left`"
              />
              <p-badge v-else-if="row.state === 'expired'" type="negative" text="Expired: Reg. data deleted" />
              <p-badge
                v-else-if="row.state === 'in_expire_cycle'"
                type="warning"
                :text="`Reg. data has ${row.days_until_retention} days left`"
              />
              <p-badge v-else text="Unpublished" />
            </div>
          </template>

          <!-- Actions -->
          <template v-else-if="header.id === 'actions'">
            <p-tooltip text="View Campaign" position="bottom">
              <p-link :href="row.demo_url" target="_blank">
                <p-button color-type="tertiary" icon="eye" size="medium" />
              </p-link>
            </p-tooltip>

            <p-tooltip text="Edit" position="bottom">
              <p-link :href="`/campaign/${row.type}/${row.id}/modify`">
                <p-button color-type="tertiary" icon="edit" size="medium" />
              </p-link>
            </p-tooltip>

            <p-tooltip v-if="row.permissions.copy" text="Copy" position="bottom">
              <p-link :href="`/campaign/copy/${row.id}`">
                <p-button color-type="tertiary" icon="copy" size="medium" />
              </p-link>
            </p-tooltip>

            <p-tooltip text="Statistics" position="bottom">
              <p-link :href="`/campaign/${row.type}/${row.id}/activity/statistics`">
                <p-button color-type="tertiary" icon="bar-chart-2" size="medium" />
              </p-link>
            </p-tooltip>

            <p-tooltip v-if="row.permissions.delete" text="Delete" position="bottom">
              <p-button color-type="tertiary" icon="trash-2" size="medium" @click="campaignDeleteConfirm = row" />
            </p-tooltip>
          </template>

          <!-- Default -->
          <template v-else>
            {{ column }}
          </template>
        </template>
      </p-table-client>
    </p-section>

    <layout-element-popup
      v-if="show2faModal"
      :url="'/account/security'"
      headline="Two-Factor Authentication"
      @closePopup="show2faModal = !show2faModal"
      @actionClosePopup="onActionClosePopup()"
    />
  </p-page>
</template>

<script lang="ts">
import { DropdownOption, TabItem } from '@/components/ui/form/types';
import { Component, Vue, Watch } from 'vue-property-decorator';
import platformUseAsset from '@/assets/images/platform-use.svg';
import { ChartAreaData, ChartAreaDataSet, ChartDoughnutDataSet } from '@/components/ui/chart/types';
import { AppRequest } from '@/app_request';
import { CampaignResource, CampaignTypeResource } from '@/types/api/campaign';
import { PaginatedResult } from '@/types/api/generic';
import { addDays, differenceInDays, format, subMonths } from 'date-fns';
import axios, { CancelTokenSource, CanceledError } from 'axios';
import { Debounce } from 'lodash-decorators';
import { TableBody } from '@/components/ui/Table.vue';
import { getDevice } from '@/utility';
import { SlideData } from '@/components/ui/custom/Slideshow.vue';
import { useUserStore } from '@/store/userStore';
import { IWorkspaceStore, useWorkspaceStore } from '@/store/workspaceStore';
import { useUsersStore } from '@/store/usersStore';
import LayoutElementPopup from '@/components/LayoutElementPopup.vue';

interface ChartApiData {
  desktop: ChartAreaData[];
  tablet: ChartAreaData[];
  mobile: ChartAreaData[];
}

interface RealtimeApiData {
  desktop: number;
  tablet: number;
  mobile: number;
}

interface CampaignTypeResourceMap {
  [key: string]: CampaignTypeResource;
}

@Component({
  components: { LayoutElementPopup }
})
export default class extends Vue {
  public userStore = useUserStore();
  public workSpaceStore = useWorkspaceStore();
  private workspaceSubscriptionUnsubscribe: (() => void) | null = null;
  private userSubscriptionUnsubscribe: (() => void) | null = null;

  public showFreemiumIntroVideo = false;
  public show2faModal = false;

  public selectedCampaign = '';
  public selectedDateRange = '1-month';
  public selectedChartDisplay = 'sessions';
  public selectedFromDate = format(subMonths(new Date(), 1), 'yyyy-MM-dd');
  public selectedToDate = format(new Date(), 'yyyy-MM-dd');

  public platformUseAsset = platformUseAsset;

  public waitingForChartData = true;
  public chartSessionData: ChartAreaDataSet[] = [];
  public chartRegistrationData: ChartAreaDataSet[] = [];
  public chartMobileColor = '';
  public chartTabletColor = '';
  public chartDesktopColor = '';
  public chartSessionAbortController: AbortController | null = null;
  public chartRegistrationAbortController: AbortController | null = null;

  public waitingForRealtimeData = true;
  public realtimeApiData: RealtimeApiData | null = null;

  public waitingForActiveCampaigns = false;
  public activeCampaigns: PaginatedResult<CampaignResource> | null = null;

  public waitingForActiveCampaignOptions = false;
  public activeCampaignOptions: DropdownOption[] = [
    {
      value: '',
      text: 'All campaigns'
    }
  ];

  private waitingForActiveCampaignOptionsToken: CancelTokenSource | null = null;
  private lastKnownCampaignSearchTerm = '';

  public waitingForLatestCampaigns = false;
  public latestCampaigns: PaginatedResult<CampaignResource> | null = null;

  public waitingForActivePopupCampaigns = false;
  public activePopupCampaigns: PaginatedResult<CampaignResource> | null = null;

  public waitingForCampaignTypes = true;
  public campaignTypes: CampaignTypeResourceMap = {};

  public campaignDeleteConfirm: CampaignResource | null = null;

  public slideShowSlides: SlideData[] = [
    {
      id: 1,
      image: 'assets/images/dashboard/freemium/ooni_pizza.jpg',
      headline: 'Ooni',
      paragraph:
        'Ooni, the popular outdoor pizza oven company, embarked on an innovative marketing journey by teaming up with Playable.',
      buttonText: 'Read Full Case',
      buttonLink: 'https://playable.com/cases/oomi/'
    },
    {
      id: 2,
      image: 'assets/images/dashboard/freemium/virgin_red.jpg',
      headline: 'Virgin Red',
      paragraph:
        'Here’s how Virgin Red uses Playable, and how one campaign achieved the lowest CPA in company history.',
      buttonText: 'Read Full Case',
      buttonLink: 'https://playable.com/cases/virgin-red/'
    },
    {
      id: 3,
      image: 'assets/images/dashboard/freemium/carlsberg_cases.jpg',
      headline: 'Carlsberg',
      paragraph: 'The beer brand wanted to engage fans and drive sales, which saw a 2x increase in redemption.',
      buttonText: 'Read Full Case',
      buttonLink: 'https://playable.com/cases/carlsberg/'
    }
  ];

  public dateRanges: TabItem[] = [
    {
      id: '1-month',
      label: '1 month'
    },
    {
      id: '6-months',
      label: '6 months'
    },
    {
      id: '12-months',
      label: '12 months'
    }
  ];

  public chartDisplayTabs: TabItem[] = [
    {
      id: 'sessions',
      label: 'Sessions'
    },
    {
      id: 'registrations',
      label: 'Registrations'
    }
  ];

  public selectedUser = '';
  public selectedGameType = '';
  public selectedStatus = '';

  public statusOptions: DropdownOption[] = [
    {
      value: '',
      text: 'All'
    },
    {
      value: 'active',
      text: 'Active'
    },
    {
      value: 'inactive',
      text: 'Inactive'
    },
    {
      value: 'paused',
      text: 'Paused'
    },
    {
      value: 'templates',
      text: 'Templates'
    },
    {
      value: 'bookmarked',
      text: 'Bookmarked'
    },
    {
      value: 'archived',
      text: 'Archived'
    }
  ];

  public get confirmModalTitle() {
    if (this.campaignDeleteConfirm) {
      return `Are you sure you want to delete "${this.campaignDeleteConfirm?.name}"?`;
    }
    return '';
  }

  public mounted() {
    this.workspaceSubscriptionUnsubscribe = this.workSpaceStore.$subscribe((mutation, state: IWorkspaceStore) => {
      const hasSeenModal = this.$cookies.get('p-first-login');

      if (!hasSeenModal && state?.workspace && state.workspace.plan_alias === 'free') {
        this.showFreemiumIntroVideo = true;
        this.$cookies.set('p-first-login', 1, '365d');
      }
    });

    this.userSubscriptionUnsubscribe = this.userStore.$subscribe(() => {
      const hasSeen2faModal = this.$cookies.get('2fa-modal');

      if (!hasSeen2faModal && this.userStore.user?.type === 'DEFAULT' && !this.userStore.user?.account_verified) {
        this.show2faModal = true;
        this.$cookies.set('2fa-modal', 1, '30');
      }
    });

    const computedDocumentStyle = window.getComputedStyle(document.documentElement);
    this.chartMobileColor = computedDocumentStyle.getPropertyValue('--color-brand-primary');
    this.chartTabletColor = computedDocumentStyle.getPropertyValue('--color-brand-tertiary');
    this.chartDesktopColor = computedDocumentStyle.getPropertyValue('--color-brand-secondary');

    this.onCampaignSearchDebounced = this.onCampaignSearchDebounced.bind(this);

    this.loadChartData();
    this.loadRealtimeData();
    this.loadActiveCampaigns();
    this.loadActivePopupCampaigns();
    this.loadActiveCampaignOptions();
    this.loadLatestCampaigns();
    this.loadCampaignTypes();
  }

  public beforeUnmount() {
    if (this.workspaceSubscriptionUnsubscribe) {
      this.workspaceSubscriptionUnsubscribe();
    }

    if (this.userSubscriptionUnsubscribe) {
      this.userSubscriptionUnsubscribe();
    }
  }

  @Watch('selectedDateRange')
  private onDateRangeChange(newTab: string) {
    switch (newTab) {
      case '1-month':
        this.selectedFromDate = format(subMonths(new Date(), 1), 'yyyy-MM-dd');
        this.selectedToDate = format(new Date(), 'yyyy-MM-dd');
        break;

      case '6-months':
        this.selectedFromDate = format(subMonths(new Date(), 6), 'yyyy-MM-dd');
        this.selectedToDate = format(new Date(), 'yyyy-MM-dd');
        break;

      case '12-months':
        this.selectedFromDate = format(subMonths(new Date(), 12), 'yyyy-MM-dd');
        this.selectedToDate = format(new Date(), 'yyyy-MM-dd');
        break;
    }
  }

  @Watch('selectedFromDate')
  private onFromDateChange() {
    this.loadChartDataDebounced();
  }

  @Watch('selectedToDate')
  private onToDateChange() {
    this.loadChartDataDebounced();
  }

  @Watch('selectedCampaign')
  private onSelectedCampaignChange() {
    this.loadChartData();
    this.loadRealtimeData();
  }

  @Watch('selectedStatus')
  @Watch('selectedUser')
  @Watch('selectedGameType')
  public onFilterChange() {
    this.loadLatestCampaigns(true);
  }

  public get chartData(): ChartAreaDataSet[] {
    if (this.workSpaceStore.workspace && !this.workSpaceStore.workspace.enable_statistics) {
      this.selectedChartDisplay = 'registrations';

      this.chartDisplayTabs = [
        {
          id: 'registrations',
          label: 'Registrations'
        }
      ];
    }

    if (this.selectedChartDisplay === 'registrations') {
      return this.chartRegistrationData;
    }

    return this.chartSessionData;
  }

  public get totalSessions(): number {
    return this.chartSessionData.reduce((previousValue, currentValue) => {
      return (
        previousValue +
        currentValue.data.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.value;
        }, 0)
      );
    }, 0);
  }

  public get totalRegistrations(): number {
    return this.chartRegistrationData.reduce((previousValue, currentValue) => {
      return (
        previousValue +
        currentValue.data.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.value;
        }, 0)
      );
    }, 0);
  }

  public get realtimeText() {
    if (!this.realtimeApiData) {
      return {
        desktop: '0',
        tablet: '0',
        mobile: '0'
      };
    }

    return {
      desktop: `${this.realtimeApiData.desktop}`,
      tablet: `${this.realtimeApiData.tablet}`,
      mobile: `${this.realtimeApiData.mobile}`
    };
  }

  public get realtimePercentage() {
    if (!this.realtimeApiData) {
      return {
        desktop: '0',
        tablet: '0',
        mobile: '0'
      };
    }

    const total = this.realtimeApiData.desktop + this.realtimeApiData.tablet + this.realtimeApiData.mobile;

    const desktop = this.realtimeApiData.desktop ? Math.floor((this.realtimeApiData.desktop / total) * 100) : 0;
    const tablet = this.realtimeApiData.tablet ? Math.floor((this.realtimeApiData.tablet / total) * 100) : 0;
    const mobile = this.realtimeApiData.mobile ? Math.floor((this.realtimeApiData.mobile / total) * 100) : 0;
    const left = total > 0 ? 100 - desktop - tablet - mobile : 0;

    return {
      desktop: `${left > 0 && mobile === 0 ? desktop + left : desktop}`,
      tablet: `${tablet}`,
      mobile: `${left > 0 && mobile > 0 ? mobile + left : mobile}`
    };
  }

  public get realtimeLabels(): string[] {
    if (
      this.realtimeApiData &&
      this.realtimeApiData.desktop + this.realtimeApiData.tablet + this.realtimeApiData.mobile === 0
    ) {
      return ['No data'];
    }

    return ['Desktop', 'Tablet', 'Mobile'];
  }

  public get realtimeChartData(): ChartDoughnutDataSet[] {
    if (!this.realtimeApiData) {
      return [];
    }

    if (this.realtimeApiData.desktop + this.realtimeApiData.tablet + this.realtimeApiData.mobile === 0) {
      return [
        {
          colors: ['#fff'],
          data: [100]
        }
      ];
    }

    return [
      {
        colors: [this.chartDesktopColor, this.chartTabletColor, this.chartMobileColor],
        data: [this.realtimeApiData.desktop, this.realtimeApiData.tablet, this.realtimeApiData.mobile]
      }
    ];
  }

  public get latestCampaignsBody(): TableBody[] {
    return (
      this.latestCampaigns?.data.map((campaign) => {
        let daysLeftLive: number | undefined = undefined;
        let daysUntilRetention: number | undefined = undefined;

        if (campaign.state === 'live') {
          const activeTo = new Date(campaign.active_to);
          const currentDate = new Date();
          daysLeftLive = differenceInDays(activeTo, currentDate);
        } else if (campaign.state === 'in_expire_cycle') {
          let retentionDays = 0;

          switch (campaign.retention) {
            case '30_days_after_expire':
              retentionDays = 30;
              break;

            case '60_days_after_expire':
              retentionDays = 60;
              break;

            case '90_days_after_expire':
              retentionDays = 90;
              break;
          }

          const expireCycleEnd = addDays(new Date(campaign.active_to), retentionDays);
          const currentDate = new Date();
          daysUntilRetention = differenceInDays(expireCycleEnd, currentDate);
        }

        return {
          ...campaign,
          typeName:
            Object.keys(this.campaignTypes).length > 0
              ? this.campaignTypes[`${campaign.type}`]?.name ?? 'N/A'
              : undefined,
          creator: campaign.created_by.name,
          days_left_live: daysLeftLive,
          days_until_retention: daysUntilRetention,
          created_on: format(new Date(campaign.created_on), 'dd-MM-yyyy')
        };
      }) ?? []
    );
  }

  public get latestCampaignCreated() {
    if (!this.latestCampaigns?.data.length || this.waitingForCampaignTypes) {
      return undefined;
    }

    const campaign = this.latestCampaigns.data[0];

    return {
      name: campaign.name,
      changed_on: format(new Date(campaign.changed_on), 'dd-MM-yyyy'),
      type: this.campaignTypes[`${campaign.type}`].name,
      url: `/campaign/${campaign.type}/${campaign.id}/modify`
    };
  }

  public async deleteCampaign() {
    if (!this.campaignDeleteConfirm || !this.campaignDeleteConfirm.permissions.delete) {
      return;
    }

    await AppRequest.delete(`/api/v1/campaign/${this.campaignDeleteConfirm.uuid}`);
    await this.loadLatestCampaigns(false);
  }

  private async loadLatestCampaigns(shouldWait = true) {
    if (shouldWait) {
      this.waitingForLatestCampaigns = true;
    }

    const response = (
      await AppRequest.get<PaginatedResult<CampaignResource>>('/api/v1/campaign', {
        params: {
          sort: ['created_on:desc', 'name:asc'],
          per_page: 5,
          with: ['sessions', 'registrations'],
          ...(this.selectedGameType && { type: [this.selectedGameType] }),
          ...(this.selectedUser && { created_by: [this.selectedUser] }),
          ...(this.selectedStatus && { status: this.selectedStatus })
        }
      })
    ).data;

    this.latestCampaigns = response;

    if (shouldWait) {
      this.waitingForLatestCampaigns = false;
    }
  }

  private async loadCampaignTypes() {
    this.waitingForCampaignTypes = true;

    const campaignTypesResponse = (await AppRequest.get<{ data: CampaignTypeResource[] }>('/api/v1/campaign-type')).data
      .data;
    const campaignTypes: CampaignTypeResourceMap = {};

    campaignTypesResponse.forEach((campaignType) => {
      campaignTypes[campaignType.alias] = campaignType;
    });

    this.campaignTypes = campaignTypes;
    this.waitingForCampaignTypes = false;
  }

  public get userOptions(): DropdownOption[] {
    const usersStore = useUsersStore();

    return [
      {
        value: '',
        text: 'All'
      },
      ...usersStore.users.map<DropdownOption>((user) => {
        return {
          value: String(user.id),
          text: user.name
        };
      })
    ];
  }

  public get campaignTypeOptions(): DropdownOption[] {
    return [
      {
        value: '',
        text: 'All'
      },
      ...Object.values(this.campaignTypes).map<DropdownOption>((type) => {
        return {
          value: type.alias,
          text: type.name
        };
      })
    ];
  }

  @Debounce(500)
  public onCampaignSearchDebounced(term?: string) {
    this.loadActiveCampaignOptions(term);
  }

  public onActionClosePopup() {
    setTimeout(() => {
      this.show2faModal = false;
    }, 500);
  }

  public async loadActiveCampaignOptions(term?: string) {
    if (!term && this.selectedCampaign && this.activeCampaignOptions.length > 1) {
      const selectedOption = this.activeCampaignOptions.find((option) => option.value === this.selectedCampaign);

      if (selectedOption && this.lastKnownCampaignSearchTerm) {
        term = this.lastKnownCampaignSearchTerm;
      }
    }

    if (term === this.lastKnownCampaignSearchTerm) {
      return;
    }

    this.lastKnownCampaignSearchTerm = term ?? '';
    this.waitingForActiveCampaignOptions = true;

    if (this.waitingForActiveCampaignOptionsToken) {
      this.waitingForActiveCampaignOptionsToken.cancel();
      this.waitingForActiveCampaignOptionsToken = null;
    }

    this.waitingForActiveCampaignOptionsToken = axios.CancelToken.source();

    try {
      const response = (
        await AppRequest.get<PaginatedResult<CampaignResource>>('/api/v1/campaign', {
          cancelToken: this.waitingForActiveCampaignOptionsToken.token,
          params: {
            sort: ['created_on:desc'],
            status: 'active',
            per_page: 20,
            ...(term && { search: term })
          }
        })
      ).data;

      this.activeCampaignOptions = [
        {
          value: '',
          text: 'All campaigns'
        }
      ].concat(
        ...response.data.map((campaign) => {
          return {
            value: campaign.uuid,
            text: campaign.name
          };
        })
      );

      this.waitingForActiveCampaignOptions = false;
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      throw e;
    }
  }

  private async loadActiveCampaigns() {
    this.waitingForActiveCampaigns = true;

    const response = (
      await AppRequest.get<PaginatedResult<CampaignResource>>('/api/v1/campaign', {
        params: {
          status: 'active',
          per_page: 1
        }
      })
    ).data;

    this.activeCampaigns = response;
    this.waitingForActiveCampaigns = false;
  }

  private async loadActivePopupCampaigns() {
    this.waitingForActivePopupCampaigns = true;

    const response = (
      await AppRequest.get<PaginatedResult<CampaignResource>>('/api/v1/campaign', {
        params: {
          status: 'active',
          type: ['popupv2'],
          per_page: 1
        }
      })
    ).data;

    this.activePopupCampaigns = response;
    this.waitingForActivePopupCampaigns = false;
  }

  private async loadRealtimeData() {
    this.waitingForRealtimeData = true;

    const response = (await AppRequest.get<RealtimeApiData>('/api/v1/campaign/statistics/realtime')).data;

    this.realtimeApiData = response;
    this.waitingForRealtimeData = false;
  }

  @Debounce(275)
  private async loadChartDataDebounced() {
    await this.loadChartData();
  }

  private async loadChartData() {
    this.waitingForChartData = true;

    try {
      await Promise.all([this.loadChartSessionData(), this.loadChartRegistrationData()]);
      this.waitingForChartData = false;
    } catch (e) {
      // Ignore cancelled errors, since we manually abort the requests on subsequent calls
      if (!(e instanceof CanceledError)) {
        throw e;
      }
    }
  }

  private async loadChartSessionData() {
    if (this.chartSessionAbortController !== null) {
      this.chartSessionAbortController.abort();
    }

    this.chartSessionAbortController = new AbortController();

    const response = (
      await AppRequest.get<ChartApiData>(
        this.selectedCampaign
          ? `/api/v1/campaign/${this.selectedCampaign}/statistics/sessions`
          : '/api/v1/campaign/statistics/sessions',
        {
          params: {
            date_from: this.selectedFromDate,
            date_to: this.selectedToDate
          },
          signal: this.chartSessionAbortController.signal
        }
      )
    ).data;

    this.chartSessionAbortController = null;

    this.chartSessionData = [
      {
        text: 'Desktop',
        color: this.chartDesktopColor,
        data: response.desktop
          .map((dataPoint) => {
            return {
              date: dataPoint.date * 1000,
              value: dataPoint.value
            };
          })
          .sort(function (a, b) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            return 0;
          })
      },
      {
        text: 'Tablet',
        color: this.chartTabletColor,
        data: response.tablet
          .map((dataPoint) => {
            return {
              date: dataPoint.date * 1000,
              value: dataPoint.value
            };
          })
          .sort(function (a, b) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            return 0;
          })
      },
      {
        text: 'Mobile',
        color: this.chartMobileColor,
        data: response.mobile
          .map((dataPoint) => {
            return {
              date: dataPoint.date * 1000,
              value: dataPoint.value
            };
          })
          .sort(function (a, b) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            return 0;
          })
      }
    ];
  }

  private async loadChartRegistrationData() {
    if (this.chartRegistrationAbortController !== null) {
      this.chartRegistrationAbortController.abort();
    }

    this.chartRegistrationAbortController = new AbortController();

    const response = (
      await AppRequest.get<ChartApiData>(
        this.selectedCampaign
          ? `/api/v1/campaign/${this.selectedCampaign}/statistics/registrations`
          : '/api/v1/campaign/statistics/registrations',
        {
          params: {
            date_from: this.selectedFromDate,
            date_to: this.selectedToDate
          }
        }
      )
    ).data;

    this.chartRegistrationAbortController = null;

    this.chartRegistrationData = [
      {
        text: 'Desktop',
        color: this.chartDesktopColor,
        data: response.desktop
          .map((dataPoint) => {
            return {
              date: dataPoint.date * 1000,
              value: dataPoint.value
            };
          })
          .sort(function (a, b) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            return 0;
          })
      },
      {
        text: 'Tablet',
        color: this.chartTabletColor,
        data: response.tablet
          .map((dataPoint) => {
            return {
              date: dataPoint.date * 1000,
              value: dataPoint.value
            };
          })
          .sort(function (a, b) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            return 0;
          })
      },
      {
        text: 'Mobile',
        color: this.chartMobileColor,
        data: response.mobile
          .map((dataPoint) => {
            return {
              date: dataPoint.date * 1000,
              value: dataPoint.value
            };
          })
          .sort(function (a, b) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            return 0;
          })
      }
    ];
  }

  public get headlineSize(): string {
    return getDevice() === 'mobile' ? '4' : '2';
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/mixins/typography';
@import '../scss/mixins/devices';

.stat-box-chart {
  width: 172px;
  height: 172px;
}

.info {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-size-small);

  &__sub {
    @include component-text-headline-small;
  }

  &__main {
    @include component-text-strong-underlined-large;

    text-overflow: ellipsis;
    overflow: hidden;
    width: 300px;
    max-width: 100%;
    white-space: nowrap;
  }
}

.devices {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-size-small);

  &__item {
    display: flex;
    align-items: flex-start;
    gap: var(--gap-size-medium);

    &-color {
      width: 18px;
      height: 18px;

      &--desktop {
        background: var(--color-brand-secondary);
      }

      &--tablet {
        background: var(--color-brand-tertiary);
      }

      &--mobile {
        background: var(--color-brand-primary);
      }
    }

    &-text {
      display: flex;
      align-items: flex-start;
      gap: var(--gap-size-small);
      min-width: 120px;

      @include component-text-strong-large;
    }
  }
}

::v-deep {
  .campaign-name {
    @include component-text-strong;

    color: var(--text-color-headline);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.creator-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.campaign-creation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-size-extra-small);
}

.decorative-text {
  display: inline-flex;
  align-items: center;
  height: 26px;

  --text-color-default: var(--text-color-help);
}

.iframe-container {
  position: relative;
  width: 100%; /* Full-width */
  padding-bottom: 88.25%; /* 564 / 640 = 0.8825 = 88.25% (Aspect ratio) */
  height: 0;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 500px;
}
</style>
